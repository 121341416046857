import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-conference',
  templateUrl: './video-conference.component.html',
  styleUrls: ['./video-conference.component.scss'],
})
export class VideoConferenceComponent implements OnInit {
  meetingLink!: any;
  roomId!: string;
  dailyCoMeetingUrl = environment.dailyCoMeetingUrl;
  administratorToken!: any;
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.roomId = params['roomId'];
    });
    const urlParams = new URLSearchParams(window.location.search);
    this.administratorToken = urlParams.get('t');
    if (
      this.administratorToken == null ||
      this.administratorToken == undefined
    ) {
      this.meetingLink = `${this.dailyCoMeetingUrl}${this.roomId}`;
    } else
      this.meetingLink = `${this.dailyCoMeetingUrl}${this.roomId}?t=${this.administratorToken}`;
    this.meetingLink = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.meetingLink
    );
  }
}
