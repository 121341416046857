<div class="login-page create-new-password">
  <mat-card class="auth-card">
    <div class="login-container">
      <h2 class="heading-login-text text-center">Create New Password</h2>
      <p>
        Please create a new password that you don't use<br />
        on any other site.
      </p>

      <form
        *ngIf="changePasswordForm"
        [formGroup]="changePasswordForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-group mb-20">
          <label>Create New Password</label>
          <div class="position-relative">
            <i
              (click)="onPasswordType()"
              class="fas {{ isPasswordType ? 'fa-eye' : 'fa-eye-slash' }}"
            >
            </i>
            <img
              src="../../../assets/images/password.png"
              class="position-icon lock"
            />
            <input
              [type]="isPasswordType ? 'text' : 'password'"
              formControlName="password"
              placeholder="8+ Characters Required"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  f['password'].invalid &&
                  (f['password'].dirty || f['password'].touched)
              }"
            />
            <ng-container *ngIf="f['password'].valid">
              <app-progress-bar [progress]="strengthData.id"></app-progress-bar>
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <label>Confirm New Password</label>
          <div class="position-relative">
            <i
              (click)="onNewPasswordType()"
              class="fas {{ isNewPasswordType ? 'fa-eye' : 'fa-eye-slash' }}"
            >
            </i>
            <img
              src="../../../assets/images/password.png"
              class="position-icon lock"
            />
            <input
              [type]="isNewPasswordType ? 'text' : 'password'"
              formControlName="confirmPassword"
              placeholder="8+ Characters Required"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  f['confirmPassword'].invalid &&
                  (f['confirmPassword'].dirty || f['confirmPassword'].touched)
              }"
            />
          </div>
        </div>

        <div class="text-center" style="margin: 40px 0 10px 0">
          <button
            type="submit"
            class="continue"
            mat-raised-button
            color="primary"
            [disabled]="!changePasswordForm.valid"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </mat-card>
</div>
