import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalConstant } from 'src/app/constants/local-constant';
import { LoginUserData, UserData } from 'src/app/models/auth.model';
import { LocalService } from 'src/app/services/local.service';
import { PracticeStaffService } from 'src/app/services/practice-staff.service';
import { SettingService } from 'src/app/services/setting.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PracticeImageService } from 'src/app/services/practice-image.services';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-edit-practice-image',
  templateUrl: './edit-practice-image.component.html',
  styleUrls: ['./edit-practice-image.component.scss'],
})
export class EditPracticeImageComponent implements OnInit {
  @ViewChild('UploadFileInput') uploadFileInput!: ElementRef;
  @ViewChild('fileUpload', { static: false })
  fileUpload!: ElementRef<HTMLInputElement>;
  myfilename = '';
  imageUrl: any;
  imagefile!: any;
  id!: number;
  isfile: boolean = true;
  userName!: string;
  firstName!: string;
  lastName!: string;
  imageChangedEvent: any = '';
  uploadButton;
  userType!: string;
  userData!: UserData;
  loginLink!: string;
  disablePic: boolean = true;
  uploaddiv: boolean = false;
  mainImage!: string;
  value: number = 0;
  scaleFactor: number = 1;
  scale: number = 1.0;
  zoomValue = 1;
  shiftX: number = 0;
  shiftY: number = 0;
  isDragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  zoomEvent: number = 1;
  minX!: any;
  maxX!: any;
  minY!: any;
  maxY!: any;
  delFlag: boolean = false;

  // ngx-image-crop properties
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  constructor(
    public dialogRef: MatDialogRef<EditPracticeImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private practiceService: PracticeStaffService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private localService: LocalService,
    private settingService: SettingService,
    private dialog: MatDialog,
    private practiceImageService: PracticeImageService
  ) {
    this.userData = data.data;
  }

  async ngOnInit(): Promise<void> {
    this.id = this.data.data.id;
    this.imageUrl = this.data.data.image;

    const userData: LoginUserData = await this.localService.getLocalData(
      LocalConstant.USER_DATA
    );
    this.userType = userData.type;
    this.getPracticeImageByUserType(this.userType);
    const name = userData.userName.split(' ');
    if (name.length === 1) {
      this.userName = name[0].substr(0, 1).charAt(0).toUpperCase().substr(0, 1);
    } else {
      this.userName =
        name[0].substr(0, 1).charAt(0).toUpperCase().substr(0, 1) +
        '' +
        name[1].substr(0, 1).charAt(0).toUpperCase().substr(0, 1) +
        '';
    }
    if (this.id) {
      await this.getPracticeDetailById(this.id);
    }
  }
  async getPracticeImageByUserType(UserTypeName: string) {
    const result = await this.settingService.getPracticeDetailImage(
      UserTypeName
    );
    if (result && result.isSuccess) {
      this.imageUrl = result.data.image;
      this.mainImage = result.data.image;
    }
  }
  previousData() {
    this.dialogRef.close();
    this.imageUrl = this.mainImage ? this.mainImage : '';
    this.myfilename = '';
    this.spinner.hide();
  }

  onUploadButtonClick() {
    this.fileUpload.nativeElement.click();
  }

  CloseDialog() {
    this.dialogRef.close();
  }
  closeBtnClick() {
    if (this.delFlag == true) {
      this.dialogRef.close({ isSuccess: true });
    } else {
      this.dialogRef.close();
    }
  }

  fileChangeEvent(fileInput: any) {
    this.disablePic = false;
    this.imageChangedEvent = fileInput;
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.imagefile = fileInput.target.files[0];
      var nameSplit = this.imagefile.name.split('.');
      var fileName = nameSplit[0];
      var fileType = nameSplit[1];
      if (
        fileType.toLowerCase() == 'png' &&
        fileType.toLowerCase() == 'jpg' &&
        fileType.toLowerCase() == 'jpeg'
      ) {
        this.snackbar.error(
          'Invalid File Type. File Type Should be Only PNG ,JPG And JPEG'
        );
        return;
      }

      const fileSizeInMB = this.imagefile.size / (1024 * 1024);
      const maxFileSizeInMB = 25.1;
      const minFileSizeInMB = 0.01;

      if (fileSizeInMB >= maxFileSizeInMB || fileSizeInMB < minFileSizeInMB) {
        // Display an error message or handle the oversized file as per your requirement
        this.disablePic = true;
        this.snackbar.error(
          'Invalid File. Size should be between 10 KB to 25 MB'
        );
        return;
      }
      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: any) => {
        this.myfilename += file.name + '';
      });
      this.uploaddiv = true;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          this.imageUrl = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      this.uploadFileInput.nativeElement.value = '';
    } else {
      this.myfilename = 'Select File';
    }
  }
  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.clientX - this.shiftX;
    this.startY = event.clientY - this.shiftY;
  }
  onMouseMove(event: MouseEvent) {
    if (this.zoomValue < 1.1) {
      this.minX = -0; // Adjust the values as needed
      this.maxX = 0;
      this.minY = -0;
      this.maxY = 0;
    } else if (this.zoomValue >= 1.1 && this.zoomValue < 1.5) {
      this.minX = -7; // Adjust the values as needed
      this.maxX = 7;
      this.minY = -12;
      this.maxY = 12;
    } else if (this.zoomValue >= 1.3 && this.zoomValue < 1.5) {
      this.minX = -15; // Adjust the values as needed
      this.maxX = 15;
      this.minY = -20;
      this.maxY = 20;
    } else if (this.zoomValue >= 1.5 && this.zoomValue < 1.7) {
      this.minX = -18; // Adjust the values  as needed
      this.maxX = 18;
      this.minY = -24;
      this.maxY = 24;
    } else if (this.zoomValue >= 1.7 && this.zoomValue <= 2) {
      this.minX = -35; // Adjust the values as needed
      this.maxX = 35;
      this.minY = -40;
      this.maxY = 40;
    }

    if (this.isDragging) {
      const newShiftX = event.clientX - this.startX;
      const newShiftY = event.clientY - this.startY;
      this.shiftX = Math.max(Math.min(newShiftX, this.maxX), this.minX);
      this.shiftY = Math.max(Math.min(newShiftY, this.maxY), this.minY);
    }
  }

  onMouseUp() {
    this.isDragging = false;
  }

  onZoomChange(event: any) {
    this.zoomValue = event.value;
    this.shiftX = 0; // Reset shiftX to zero
    this.shiftY = 0;
  }
  saveImage() {
    html2canvas(document.querySelector('.cropper-container') as any).then(
      (canvas) => {
        const base64Image = canvas.toDataURL('image/png', 1);
        const blobData = this.dataURItoBlob(base64Image);
        const file = new File([blobData], 'cropped_image.png', {
          type: 'image/png',
        });
        const formData = new FormData();
        formData.append('image', file);
        this.uploadFormData(formData);
      }
    );
  }

  async UploadImage() {
    const blobData = this.dataURItoBlob(this.imageUrl);
    const file = new File([blobData], 'cropped_image.png', {
      type: 'image/png',
    });
    const formData = new FormData();
    formData.append('image', file);
    this.uploadFormData(formData);
  }

  async getPracticeDetailById(id: number) {
    const result = await this.settingService.getPracticeDetail(id);
    if (result && result.isSuccess) {
      this.userData = result.data;
      this.loginLink = `http://${this.userData.practiceLink}.treo.cloud`;
    }
    this.spinner.hide();
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.imageUrl = event.base64;
  //   this.imagefile = this.dataURItoBlob(this.imageUrl);
  // }

  dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  async deleteImage() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '460px',
      disableClose: true,
      data: {
        title: 'Confirm Delete',
        content: 'Are you sure you want to delete image?',
      },
    });
    dialogRef.afterClosed().subscribe(async (response: boolean) => {
      if (response) {
        this.spinner.show();
        const result = await this.practiceService.deletePractitionerImage(
          this.userType
        );
        if (result && result.isSuccess) {
          const practiceImage =
            await this.settingService.getPracticeDetailImage(this.userType);
          this.imageUrl = practiceImage.data.image;
          this.practiceImageService.updateHeaderImageUrl('');
          this.delFlag = false;
          this.spinner.hide();
          this.imageUrl = '';
          this.practiceImageService.updateProfileImageUrl(this.imageUrl);
        } else {
          this.spinner.hide();
        }
      }
    });
  }
  async uploadFormData(formData: FormData) {
    formData.append('userType', this.userType);
    this.spinner.show();
    if (this.userType === 'admin') {
      const result = await this.practiceService.UploadPracticeImage(formData);
      this.spinner.show();
      if (result && result.isSuccess) {
        this.spinner.show();
        this.practiceImageService.updateHeaderImageUrl(this.imageUrl);
        this.uploaddiv = false;
        this.myfilename = '';
        this.dialogRef.close({ isSuccess: true });
      }
    } else {
      const result = await this.practiceService.UploadOthersPracticeImage(
        formData
      );
      this.spinner.show();
      if (result && result.isSuccess) {
        this.practiceImageService.updateHeaderImageUrl(this.imageUrl);
        this.uploaddiv = false;
        this.myfilename = '';
        this.dialogRef.close({ isSuccess: true });
        this.spinner.hide();
      }
    }
    this.spinner.hide();
  }

  // ngx-image-cropper functions
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  // handle image load
  loadImageFailed() {
    this.snackbar.error('Error while loading image, please retry!');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  rightRotate() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation++,
    };
  }
  leftRotate() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation--,
    };
  }
}
