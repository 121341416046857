<div class="reset-password login-page">
  <mat-card class="auth-card">
    <div class="login-container">
      <h2 class="heading-login-text text-center">Reset Your Password?</h2>

      <p>
        No worries! Just enter your registered email id or mobile number and
        we'll send you a verification code.
      </p>

      <form
        *ngIf="resetPasswordForm"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="position-relative reset-password-height">
          <div class="form-group">
            <label>Email or Mobile Number</label>

            <div
              class="enter-phone form-control d-flex pl-1 reset-password-height justify-content-between"
            >
              <ng-container *ngIf="selectedType === 'phone'">
                <div class="country-image">
                  <i class="fas fa-caret-down arrow-down"></i>
                  <img
                    src="{{
                      'http://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        selectedCountry +
                        '.svg'
                    }}"
                    width="24px"
                    height="24px"
                  />
                  <mat-form-field appearance="fill">
                    <input
                      type="text"
                      matInput
                      maxlength="7"
                      [formControl]="dialCodeControl"
                      [matAutocomplete]="auto"
                      (blur)="onBlurChangeCountry($event)"
                      (paste)="$event.preventDefault()"
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      class="flag-dropdown"
                    >
                      <mat-option
                        *ngFor="let item of filteredCountryCodes | async"
                        [value]="item"
                        (click)="onChangeCountry(item)"
                      >
                        <img
                          src="{{
                            'http://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                              item.code +
                              '.svg'
                          }}"
                          width="24px"
                          height="24px"
                        />&nbsp; <span>{{ item.dialCode }}</span> &nbsp;
                        <span>{{ item.code }}</span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>

                <div class="position-relative width-phone">
                  <img
                    src="../../../assets/images/Telephone.png"
                    class="position-icon lock"
                  />
                  <input
                    type="text"
                    formControlName="phone"
                    placeholder="1489237420"
                    class="form-control phone"
                    (keypress)="commonService.numberOnly($event)"
                    (paste)="$event.preventDefault()"
                    maxlength="10"
                    [ngClass]="{
                      'is-invalid':
                        f['phone'].invalid &&
                        (f['phone'].dirty || f['phone'].touched)
                    }"
                  />
                </div>
              </ng-container>

              <ng-container *ngIf="selectedType === 'email'">
                <div class="position-relative width-email">
                  <img
                    src="../../../assets/images/email.png"
                    class="position-icon Email"
                  />
                  <input
                    type="text"
                    formControlName="email"
                    placeholder="johndoe123@gmail.com"
                    class="form-control w-100"
                    [ngClass]="{
                      'is-invalid':
                        f['email'].invalid &&
                        (f['email'].dirty || f['email'].touched)
                    }"
                  />
                </div>
              </ng-container>

              <div class="select-email">
                <mat-form-field appearance="fill">
                  <mat-select
                    (selectionChange)="onChangeType($event.value)"
                    [(value)]="selectedType"
                  >
                    <mat-option [value]="'email'">Email</mat-option>
                    <mat-option [value]="'phone'">Mobile</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="continue mt-5 mb-2"
            mat-raised-button
            color="primary"
          >
            Send Verification Code
          </button>
        </div>
      </form>

      <div class="already text-center" style="margin-top: 16px">
        Back to &nbsp;<a routerLink="/login">Login?</a>
      </div>
    </div>
  </mat-card>
</div>
