<div class="login-page">
  <mat-card class="auth-card">
    <div class="login-container">
      <app-logo [heading]="'Set New Password'"></app-logo>

      <p>Please create a new password that you don't use on any other site.</p>

      <form
        *ngIf="changePasswordStaffForm"
        [formGroup]="changePasswordStaffForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="form-group">
          <label>Create New Password</label>
          <div class="position-relative">
            <i
              (click)="onPasswordType()"
              class="fas {{ isPasswordType ? 'fa-eye' : 'fa-eye-slash' }}"
            >
            </i>
            <input
              [type]="isPasswordType ? 'text' : 'password'"
              formControlName="password"
              placeholder="JohnDoe@123"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  f['password'].invalid &&
                  (f['password'].dirty || f['password'].touched)
              }"
            />
            <ng-container *ngIf="f['password'].valid">
              <app-progress-bar [progress]="strengthData.id"></app-progress-bar>
            </ng-container>
          </div>
        </div>

        <div class="form-group">
          <label>Confirm New Password</label>
          <div class="position-relative">
            <i
              (click)="onNewPasswordType()"
              class="fas {{ isNewPasswordType ? 'fa-eye' : 'fa-eye-slash' }}"
            >
            </i>
            <input
              [type]="isNewPasswordType ? 'text' : 'password'"
              formControlName="confirmPassword"
              placeholder="JohnDoe@123"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  f['confirmPassword'].invalid &&
                  (f['confirmPassword'].dirty || f['confirmPassword'].touched)
              }"
            />
          </div>
        </div>

        <button
          type="submit"
          class="continue"
          mat-raised-button
          color="primary"
          [disabled]="!changePasswordStaffForm.valid"
        >
          Update
        </button>
      </form>
    </div>
  </mat-card>
</div>
