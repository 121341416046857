import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VerifyAccountComponent } from './components/verify-account/verify-account.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './shared/modules/material.module';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { AuthInterceptor } from './interceptors/authInterceptor';
import { ScheduleModule } from './shared/modules/schedule.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  declarations: [AppComponent, VerifyAccountComponent, NumberOnlyDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    // ScheduleModule,
    NgxDocViewerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    BnNgIdleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
