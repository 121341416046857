import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserData } from '../models/auth.model';
import { BaseResonse } from '../models/common.model';
import {
  AddTags,
  ChangePassword,
  UpdateTagStatus,
} from '../models/setting.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  apiUrl = environment.apiUrl;

  constructor(public httpService: HttpService) {}

  getPracticeDetail(userId: number): Promise<BaseResonse> {
    return this.httpService.get(`api/setting/detail/${userId}`);
  }
  getPracticeDetailImage(UserType: string): Promise<BaseResonse> {
    return this.httpService.get(`api/setting/get-image-user/${UserType}`);
  }
  updatePractice(data: UserData): Promise<BaseResonse> {
    return this.httpService.post(`api/setting/update`, data);
  }

  getTimeOut(type: string, access: string): Promise<BaseResonse> {
    return this.httpService.get(
      `api/setting/get-timeout?type=${type}&access=${access}`
    );
  }

  updateTimeout(data: UserData): Promise<BaseResonse> {
    return this.httpService.post(`api/setting/update-timeout`, data);
  }

  changePassword(data: ChangePassword): Promise<BaseResonse> {
    return this.httpService.put(`api/setting/change-password-user`, data);
  }

  addTags(data: AddTags): Promise<BaseResonse> {
    return this.httpService.post(`api/tags/add-tag`, data);
  }

  updateTag(data: AddTags): Promise<BaseResonse> {
    return this.httpService.put(`api/tags/update-tag`, data);
  }

  getTag(payload: any): Promise<BaseResonse> {
    return this.httpService.post(`api/tags/get-tags`, payload);
  }

  deleteTag(id: number): Promise<BaseResonse> {
    return this.httpService.delete(`api/tags/delete-tag/${id}`);
  }

  updateTagStatus(data: UpdateTagStatus): Promise<BaseResonse> {
    return this.httpService.patch(`api/tags/update-tag-status`, data);
  }

  uploadDocument(data: FormData, id: number): Promise<BaseResonse> {
    return this.httpService.post(`api/patient/upload-patient-docs/${id}`, data);
  }
}
