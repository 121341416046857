import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { PatientDataSource } from 'src/app/models/patient.mode';
import { PatientService } from 'src/app/services/patient.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PracticeImageService } from 'src/app/services/practice-image.services';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-edit-profile-image',
  templateUrl: './edit-profile-image.component.html',
  styleUrls: ['./edit-profile-image.component.scss'],
})
export class EditProfileImageComponent implements OnInit {
  @ViewChild('UploadFileInput') uploadFileInput!: ElementRef;
  @ViewChild('imageElement') imageElement!: ElementRef;
  @ViewChild('fileUpload', { static: false })
  fileUpload!: ElementRef<HTMLInputElement>;
  myfilename = '';
  imageUrl: any;
  imagefile!: any;
  id!: number;
  isfile: boolean = true;
  userName!: string;
  firstName!: string;
  lastName!: string;
  personalInfo!: PatientDataSource;
  patientData!: PatientDataSource;
  imageChangedEvent: any = '';
  uploadButton;
  mainImage!: string;
  disablePic: boolean = true;
  uploaddiv: boolean = false;
  value: number = 0;
  scaleFactor: number = 1;
  scale: number = 1.0;
  zoomLevel = 0;
  zoomValue = 1;
  shiftX: number = 0;
  shiftY: number = 0;
  isDragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  zoomEvent: number = 1;
  minX!: any;
  maxX!: any;
  minY!: any;
  maxY!: any;
  delFlag: boolean = false;

  // ngx-image-crop properties
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  constructor(
    public dialogRef: MatDialogRef<EditProfileImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private patientService: PatientService,
    private snackbar: SnackBarService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private practiceImageService: PracticeImageService
  ) {
    this.id = data.data.id;
    this.mainImage = data.data.image;
    this.personalInfo = data.data;
  }

  async ngOnInit(): Promise<void> {
    this.imageUrl = this.data.data.image;
    if (this.id) {
      await this.getPatientById(this.id);
    }
  }

  onUploadButtonClick() {
    this.fileUpload.nativeElement.click();
  }

  CloseDialog() {
    this.dialogRef.close(false);
  }

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.clientX - this.shiftX;
    this.startY = event.clientY - this.shiftY;
  }
  onMouseMove(event: MouseEvent) {
    if (this.zoomValue < 1.1) {
      this.minX = -0; // Adjust the values as needed
      this.maxX = 0;
      this.minY = -0;
      this.maxY = 0;
    } else if (this.zoomValue >= 1.1 && this.zoomValue < 1.5) {
      this.minX = -7; // Adjust the values as needed
      this.maxX = 7;
      this.minY = -12;
      this.maxY = 12;
    } else if (this.zoomValue >= 1.3 && this.zoomValue < 1.5) {
      this.minX = -15; // Adjust the values as needed
      this.maxX = 15;
      this.minY = -20;
      this.maxY = 20;
    } else if (this.zoomValue >= 1.5 && this.zoomValue < 1.7) {
      this.minX = -18; // Adjust the values  as needed
      this.maxX = 18;
      this.minY = -24;
      this.maxY = 24;
    } else if (this.zoomValue >= 1.7 && this.zoomValue <= 2) {
      this.minX = -35; // Adjust the values as needed
      this.maxX = 35;
      this.minY = -40;
      this.maxY = 40;
    }

    if (this.isDragging) {
      const newShiftX = event.clientX - this.startX;
      const newShiftY = event.clientY - this.startY;
      this.shiftX = Math.max(Math.min(newShiftX, this.maxX), this.minX);
      this.shiftY = Math.max(Math.min(newShiftY, this.maxY), this.minY);
    }
  }

  onMouseUp() {
    this.isDragging = false;
  }

  onZoomChange(event: any) {
    this.zoomValue = event.value;
    this.shiftX = 0; // Reset shiftX to zero
    this.shiftY = 0;
  }
  saveImage() {
    html2canvas(document.querySelector('.cropper-container') as any).then(
      (canvas) => {
        const base64Image = canvas.toDataURL('image/jpeg', 1);
        const blobData = this.dataURItoBlob(base64Image);
        const file = new File([blobData], 'cropped_image.png', {
          type: 'image/png',
        });
        const formData = new FormData();
        formData.append('image', file);
        this.uploadFormData(formData);
      }
    );
  }

  fileChangeEvent(fileInput: any) {
    this.disablePic = false;
    this.imageChangedEvent = fileInput;
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.imagefile = fileInput.target.files[0];
      var nameSplit = this.imagefile.name.split('.');
      var fileName = nameSplit[0];
      var fileType = nameSplit[1];
      if (
        fileType.toLowerCase() == 'png' &&
        fileType.toLowerCase() == 'jpg' &&
        fileType.toLowerCase() == 'jpeg'
      ) {
        this.snackbar.error(
          'Invalid File Type. File Type Should be Only PNG ,JPG And JPEG'
        );
        return;
      }

      const fileSizeInMB = this.imagefile.size / (1024 * 1024);
      const maxFileSizeInMB = 25.1;
      const minFileSizeInMB = 0.01;

      if (fileSizeInMB >= maxFileSizeInMB || fileSizeInMB < minFileSizeInMB) {
        // Display an error message or handle the oversized file as per your requirement
        this.disablePic = true;
        this.snackbar.error(
          'Invalid File. Size should be between 10kb KB to 25 MB'
        );
        return;
      }
      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: any) => {
        this.myfilename += file.name + '';
      });
      this.uploaddiv = true;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          this.imageUrl = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      this.uploadFileInput.nativeElement.value = '';
    } else {
      this.myfilename = 'Select File';
    }
  }
  previousData() {
    this.imageUrl = this.mainImage ? this.mainImage : '';
    this.myfilename = '';
    this.dialogRef.close();
  }
  closeBtnClick() {
    if (this.delFlag == true) {
      this.dialogRef.close({ isSuccess: true });
    } else {
      this.dialogRef.close();
    }
  }

  async uploadFormData(formData: FormData) {
    this.spinner.show();
    const result = await this.patientService.UploadPatientImage(
      this.id,
      formData
    );
    if (result && result.isSuccess) {
      this.practiceImageService.updateProfileImageUrl(result.data[0].image);
      this.spinner.hide();
      this.dialogRef.close({ isSuccess: true });
      this.myfilename = '';
    } else {
      this.spinner.hide();
    }
  }
  async getPatientById(id: number): Promise<void> {
    const result = await this.patientService.getPatientById(id);
    if (result && result.isSuccess) {
      this.patientData = result.data;
      const name = this.patientData?.name?.split(' ');
      this.firstName = this.patientData?.firstName;
      this.lastName = this.patientData?.lastName;
      this.userName =
        this.firstName.charAt(0).toUpperCase() +
        '' +
        this.lastName.charAt(0).toUpperCase();
    }
  }
  async deleteImage() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '460px',
      disableClose: true,
      data: {
        title: 'Confirm Delete',
        content: 'Are you sure you want to delete image ?',
      },
    });
    dialogRef.afterClosed().subscribe(async (response: boolean) => {
      if (response) {
        const result = await this.patientService.deletePatientImage(this.id);
        if (result && result.isSuccess) {
          const patientData = await this.patientService.getPatientById(this.id);
          this.imageUrl = patientData.data.image;
          this.spinner.hide();
          this.imageUrl = '';
          this.delFlag = false;
          this.practiceImageService.updateProfileImageUrl('deleted');
          this.dialogRef.close({ isSuccess: true });
        } else {
          this.spinner.hide();
        }
      }
    });
  }
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
  }

  // ngx-image-cropper functions
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  // handle image load
  loadImageFailed() {
    this.snackbar.error('Error while loading image, please retry!');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  rightRotate() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation++,
    };
  }
  leftRotate() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation--,
    };
  }
}
