<div>
  <div class="row mx-0 align-items-center">
    <div class="col-5 pr-0 hide-mobile overflow-div">
      <div class="left-content">
        <img src="../../../assets/images/Mask group.png" class="logo-image" />
        <h1 class="text-center">Virtual Clinic Platform For Care Teams</h1>
        <p class="text-center text">
          Practice management for physical and virtual clinics, hospital in the
          <br />
          home and out patients
        </p>
        <img
          src="../../../assets/images/Login-side-image.svg"
          class="big-image"
        />

        <ul>
          <li><img src="../../../assets/images/login-side-icon-1.svg" /></li>
          <li><img src="../../../assets/images/login-side-icon-2.svg" /></li>
          <li><img src="../../../assets/images/login-side-icon-3.svg" /></li>
        </ul>
      </div>
    </div>
    <div class="col-7 p-0 right-overflow">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
