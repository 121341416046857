import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseResonse } from '../models/common.model';
import {
  AddPracticeStaff,
  SetPasswordStaff,
  UpdateInformation,
  UpdateStatus,
} from '../models/setting.model';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeStaffService {
  apiUrl = environment.apiUrl;

  constructor(public httpService: HttpService) {}

  getPracticeStaff(payload: any): Promise<BaseResonse> {
    return this.httpService.post(`api/setting/get-practice-staff`, payload);
  }

  getPracticeStaffSchedule(): Promise<BaseResonse> {
    return this.httpService.get(`api/setting/get-practice-staff-scheduler`);
  }

  addPracticeStaff(data: AddPracticeStaff): Promise<BaseResonse> {
    return this.httpService.post(`api/setting/add-practice-staff`, data);
  }

  updatePracticeStaff(data: AddPracticeStaff): Promise<BaseResonse> {
    return this.httpService.post(`api/setting/update-practice-staff`, data);
  }

  deletePracticeStaff(staffId: number): Promise<BaseResonse> {
    return this.httpService.delete(
      `api/setting/delete-practice-staff/${staffId}`
    );
  }
  deletePractitionerImage(data: any) {
    return this.httpService.delete(`api/setting/delete-image-user/${data}`);
  }

  verifyStaff(token: string, id: number): Promise<any> {
    return this.httpService.get(`api/setting/verify-staff/${token}/${id}`);
  }

  setPasswordStaff(data: SetPasswordStaff) {
    return this.httpService.post(`api/setting/set-password-staff`, data);
  }

  updateStatus(data: UpdateStatus): Promise<BaseResonse> {
    return this.httpService.patch(`api/setting/update-practice-status`, data);
  }

  reSendEmail(id: number, data: any): Promise<BaseResonse> {
    return this.httpService.post(`api/setting/resend-email/${id}`, data);
  }

  UploadPracticeImage(data: any): Promise<BaseResonse> {
    return this.httpService.put(`api/setting/upload-image-tenant`, data);
  }
  UploadOthersPracticeImage(data: any): Promise<BaseResonse> {
    return this.httpService.put(`api/setting/upload-image-staff`, data);
  }

  updateAppointmentStatus(data: UpdateInformation): Promise<BaseResonse> {
    return this.httpService.put(`api/schedule/update-appointment-status`, data);
  }
}
