import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordStrength } from 'check-password-strength';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorConstant } from 'src/app/constants/error.constant';
import { SuccessConstant } from 'src/app/constants/success.contant';
import Validation from 'src/app/helpers/must-match';
import { PasswordStrength } from 'src/app/models/common.model';
import { SetPasswordStaff } from 'src/app/models/setting.model';
import { LocalService } from 'src/app/services/local.service';
import { PracticeStaffService } from 'src/app/services/practice-staff.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-password-staff',
  templateUrl: './password-staff.component.html',
  styleUrls: ['./password-staff.component.scss'],
})
export class PasswordStaffComponent implements OnInit {
  changePasswordStaffForm!: FormGroup;
  isSubmitted = false;
  strengthData!: PasswordStrength;
  isPasswordType: boolean = false;
  isNewPasswordType: boolean = false;
  id!: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private localService: LocalService,
    private practiceStaffService: PracticeStaffService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    const token = this.route.snapshot.paramMap.get('token');
    this.id = Number(this.route.snapshot.paramMap.get('id'));

    if (token && this.id) {
      await this.verifyStaff(token, this.id);
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.snackbar.error(ErrorConstant.LINK_INVALID);
      this.router.navigate(['']);
    }
    await this.buildForm();
  }

  async buildForm(): Promise<void> {
    this.changePasswordStaffForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
        ]),
        confirmPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
        ]),
      },
      {
        validators: [Validation.match('password', 'confirmPassword')],
      }
    );

    this.changePasswordStaffForm.valueChanges.subscribe((data) => {
      if (data.password) {
        this.strengthData = passwordStrength(data.password);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.changePasswordStaffForm.controls;
  }

  onPasswordType(): void {
    this.isPasswordType = !this.isPasswordType;
  }

  onNewPasswordType(): void {
    this.isNewPasswordType = !this.isNewPasswordType;
  }

  async onSubmit(): Promise<void> {
    this.isSubmitted = true;
    if (this.changePasswordStaffForm.invalid) {
      return;
    }

    this.spinner.show();
    const data: SetPasswordStaff = {
      id: this.id,
      password: this.changePasswordStaffForm.value.password,
    };

    const result = await this.practiceStaffService.setPasswordStaff(data);

    if (result && result.isSuccess) {
      if (result?.data && result?.data?.practiceLink) {
        await this.localService.removeAllLocalData();
        this.snackbar.success(SuccessConstant.SET_PASSWORD_STAFF);
        setTimeout(() => {
          this.spinner.hide();
          const practiceLink = `https://${result?.data?.practiceLink}.treo.cloud/login`;
          window.location.href = practiceLink;
        }, 2500);
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  async verifyStaff(token: string, id: number): Promise<void> {
    const result = await this.practiceStaffService.verifyStaff(token, id);
    if (!result) {
      this.spinner.hide();
      this.router.navigate(['/login']);
    }
  }
}
