import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorConstant } from 'src/app/constants/error.constant';
import { SuccessConstant } from 'src/app/constants/success.contant';
import { AuthService } from 'src/app/services/auth.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private snackbar: SnackBarService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    const token = this.route.snapshot.paramMap.get('token');
    const id = this.route.snapshot.paramMap.get('id');
    if (token && id) {
      await this.verifyAccount(token, id);
    } else {
      this.spinner.hide();
      this.snackbar.error(ErrorConstant.LINK_INVALID);
      this.router.navigate(['']);
    }
  }

  async verifyAccount(token: string, id: string): Promise<void> {
    const result = await this.authService.verifyAccount(token, id);
    if (result && result?.data) {
      if (result?.data?.practiceLink) {
        this.snackbar.success(SuccessConstant.ACCOUNT_VERIFIED);
        setTimeout(() => {
          this.spinner.hide();
          const practiceLink = `http://${result?.data?.practiceLink}.treo.cloud/login`;
          window.location.href = practiceLink;
        }, 2500);
      }
    } else {
      this.spinner.hide();
      this.snackbar.error(ErrorConstant.VERIFICATION_ERROR);
      this.router.navigate(['']);
    }
  }
}
