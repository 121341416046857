import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-conference-end',
  templateUrl: './video-conference-end.component.html',
  styleUrls: ['./video-conference-end.component.scss'],
})
export class VideoConferenceEndComponent implements OnInit {
  isHidden = false;

  constructor() {}

  ngOnInit(): void {}

  backToLogin() {
    // this.router.navigateByUrl('/login');
    // window.location.replace('about:blank');
    // // this.isHidden = true;
    // window.close();

    let url = window.location.origin + '/login';
    window.open(url, '_blank');
  }
}
