<div class="misc-wrapper">
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h1 class="mb-1">Page Not Found</h1>
      <p class="mb-2">Oops! The requested URL was not found on this server.</p>
      <button
        type="button"
        class="common-button ml-3 mt-3"
        mat-raised-button
        color="primary"
        (click)="onClickBack()"
      >
        Back to home
      </button>
    </div>
  </div>
</div>
