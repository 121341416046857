<div
  class="dialog vital-info editprofileinfo"
  [ngClass]="uploaddiv ? 'w-700' : 'w-400'"
>
  <div class="dialog-header">
    <h1 mat-dialog-title>Profile Image</h1>
    <img
      src="../../../../assets/images/Plus.png"
      (click)="dialogRef.close()"
      class="cursor-pointer"
      style="width: 17px"
    />
  </div>
  <div *ngIf="!uploaddiv">
    <div
      class="row mat-dialog-content d-flex justify-content-around align-items-center main_profile"
    >
      <div class="vital-info-inner">
        <div class="">
          <div class="mb-2 align-items-center">
            <div
              class="profile-pic d-flex justify-content-center"
              *ngIf="!imageUrl"
            >
              <div
                class="dispaly-pofile d-flex align-items-center justify-content-center"
              >
                <span>{{ userName }}</span>
              </div>
            </div>
            <div *ngIf="imageUrl" class="d-flex justify-content-center">
              <div id="main-image">
                <img
                  width="75px"
                  height="75px"
                  [src]="imageUrl"
                  class="image mainImg"
                />
              </div>
            </div>
            <div
              (click)="onUploadButtonClick()"
              class="d-flex justify-content-center align-items-center mt-3 mb-1"
            >
              <button type="button" mat-button class="common-button uploadbtn">
                <i class="fal fa-cloud-upload mr-2"></i>
                <span>Upload</span>
              </button>
              <input
                type="file"
                #fileUpload
                accept="image/png, image/jpeg ,image/jpg"
                style="display: none"
                (change)="fileChangeEvent($event)"
              />
            </div>
            <div class="fw-bold">
              <!-- <span>{{ warningMsg }}</span> -->
              <span>Image size should be between 10 KB to 25 MB</span><br />
              <span>File type should be only PNG ,JPG And JPEG</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end dialog-actions" *ngIf="!imageUrl"></div>
    <div class="row justify-content-between dialog-actions" *ngIf="imageUrl">
      <button
        class="deletebtn"
        type="button"
        mat-button
        [disabled]="!imageUrl"
        (click)="deleteImage()"
      >
        Delete
      </button>
    </div>
  </div>
  <div *ngIf="uploaddiv">
    <div
      class="row mat-dialog-content d-flex justify-content-around align-items-center main_profile"
    >
      <div class="vital-info-inner">
        <div class="">
          <div class="" style="margin: 0 0 20px 0">
            <!-- <div class="profile-pic" *ngIf="!imageUrl"></div> -->
            <div class="d-flex align-items-center" style="gap: 60px">
              <div class="">
                <div class="cropper-container">
                  <img class="image-preview" [src]="croppedImage" />
                </div>
                <div (click)="onUploadButtonClick()" class="d-flex ml-0 mb-1">
                  <button
                    type="button"
                    mat-button
                    class="common-button uploadbtn crop-upload-btn mx-auto mt-2"
                  >
                    <i class="fal fa-cloud-upload mr-2"></i>
                    <span>Upload</span>
                  </button>
                  <input
                    type="file"
                    #fileUpload
                    accept="image/png, image/jpeg ,image/jpg"
                    style="display: none"
                    (change)="fileChangeEvent($event)"
                  />
                </div>
              </div>
              <div class="mt-2">
                <image-cropper
                  [imageChangedEvent]="imageChangedEvent"
                  [aspectRatio]="4 / 4"
                  [resizeToWidth]="300"
                  [cropperMinWidth]="500"
                  [onlyScaleDown]="true"
                  [roundCropper]="false"
                  [canvasRotation]="canvasRotation"
                  [transform]="transform"
                  [alignImage]="'left'"
                  [style.display]="showCropper ? null : 'none'"
                  format="png"
                  (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()"
                  (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
                <div
                  class="image-buttons d-flex justify-content-around mb-1 mt-2"
                >
                  <i
                    class="fas fa-undo"
                    matTooltip="Rotate left"
                    (click)="rotateLeft()"
                  ></i>
                  <i
                    class="fas fa-redo"
                    matTooltip="Rotate right"
                    (click)="rotateRight()"
                  ></i>
                  <i
                    class="fas fa-arrows-alt-h"
                    matTooltip="Flip horizontal"
                    (click)="flipHorizontal()"
                  ></i>
                  <i
                    class="fas fa-arrows-alt-v"
                    matTooltip="Flip vertical"
                    (click)="flipVertical()"
                  ></i>
                  <i
                    [ngClass]="
                      containWithinAspectRatio ? 'fas fa-fill' : 'fas fa-box'
                    "
                    [matTooltip]="
                      containWithinAspectRatio
                        ? 'Contain within aspect ratio'
                        : 'Fill aspect ratio'
                    "
                    (click)="toggleContainWithinAspectRatio()"
                  ></i>
                </div>
                <div
                  class="image-buttons d-flex justify-content-around mt-2 mb-2"
                >
                  <i
                    class="fas fa-trash-restore"
                    matTooltip="Reset image"
                    (click)="resetImage()"
                  ></i>
                  <i
                    class="fas fa-hand-point-left"
                    matTooltip="Left rotation"
                    (click)="leftRotate()"
                  ></i>
                  <i
                    class="fas fa-hand-point-right"
                    matTooltip="Right rotation"
                    (click)="rightRotate()"
                  ></i>
                  <i
                    class="fas fa-search-minus"
                    matTooltip="Zoom -"
                    (click)="zoomOut()"
                  ></i>
                  <i
                    class="fas fa-search-plus"
                    matTooltip="Zoom +"
                    (click)="zoomIn()"
                  ></i>
                </div>
              </div>
            </div>
            <!-- <div (click)="onUploadButtonClick()" class="d-flex ml-0 mb-1">
              <button
                type="button"
                mat-button
                class="common-button uploadbtn crop-upload-btn"
              >
                <i class="fal fa-cloud-upload mr-2"></i>
                <span>Upload</span>
              </button>
              <input
                type="file"
                #fileUpload
                accept="image/png, image/jpeg ,image/jpg"
                style="display: none"
                (change)="fileChangeEvent($event)"
              />
            </div> -->

            <!-- <div class="imagediv">
              <div
                class="cropper-container"
                id="imageContainer"
                (mousedown)="onMouseDown($event)"
              >
                <img
                  #imageElement
                  [src]="imageUrl"
                  alt="Image"
                  [style.transform]="
                    'scale(' +
                    zoomValue +
                    ') translate(' +
                    shiftX / zoomValue +
                    'px, ' +
                    shiftY / zoomValue +
                    'px)'
                  "
                  class="circle-image"
                  (dragover)="onMouseMove($event)"
                  (mouseup)="onMouseUp()"
                  [class.hide-ghost-image]="isDragging"
                />
              </div>
              <div class="w-100 d-flex">
                <i class="fa fa-minus fa-sm d-flex align-items-center mr-3"></i>
                <mat-slider
                  [(ngModel)]="zoomValue"
                  matSliderThumb
                  min="1"
                  max="2"
                  step="0.1"
                  (input)="onZoomChange($event)"
                ></mat-slider>
                <i class="fa fa-plus fa-sm d-flex align-items-center ml-3"></i>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row dialog-actions justify-content-end">
      <button
        type="submit"
        mat-button
        (click)="saveImage()"
        class="common-button"
        [disabled]="disablePic"
      >
        Save
      </button>
    </div>
  </div>
</div>
