import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PracticeImageService {
  private headerImageUrl = new BehaviorSubject<string>('');
  private profileImageUrl = new BehaviorSubject<string>('');
  headerImageUrl$ = this.headerImageUrl.asObservable();
  profileImageUrl$ = this.profileImageUrl.asObservable();

  updateHeaderImageUrl(url: string) {
    this.headerImageUrl.next(url);
  }
  updateProfileImageUrl(url: string) {
    this.profileImageUrl.next(url);
  }
}
