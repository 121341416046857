<div class="progress password">
  <div
    *ngFor="let item of progressItems"
    class="progress-bar stregnth-{{ progress }}"
    role="progressbar"
    style="width: 25%"
    aria-valuenow="25"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
